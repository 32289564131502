/**
 * Vision.tsx
 *
 * Component for the vision section of the website.
 *
 */
import React, { useEffect, useRef, useState } from "react";

import { useIntersection } from "react-use";

import "../Homepage.scss";
import "./Vision.scss";
import vision_img from "../../../assets/img/vision-cube.png";

export const Vision: React.FC = () => {
  const section_ref = useRef<HTMLElement>(null);
  // Use the Intersection Observer API to track the visibility of the text elements.
  const intersection = useIntersection(section_ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  const [headingClass, setHeadingClass] = useState("");
  const [paragraphClass, setParagraphClass] = useState("");
  let heading_has_appeared = false;

  useEffect(() => {
    if (intersection?.isIntersecting && !heading_has_appeared) {
      heading_has_appeared = true;
      setHeadingClass("heading-appear");
      setParagraphClass("paragraph-appear");
    }
  }, [intersection?.isIntersecting]);

  return (
    <section className="text-section" id="vision-section" ref={section_ref}>
      <main className="text-area">
        <h2 className={headingClass}>Vision</h2>
        <p className={paragraphClass}>
          We envision to: (1) become a national organization that efficiently
          contributes to the development of the nation and its people; and (2)
          establish a company that offers accessible, effective, and reliable
          services to the public.
        </p>
      </main>
      <figure className="cube-area">
        <img src={vision_img} className="section-img"></img>
      </figure>
    </section>
  );
};
