/**
 * Welcome.tsx
 *
 * Component for the Welcome section.
 *
 */

import React from "react";

import { StaticCube } from "../../StaticCube/StaticCube";

import "./Welcome.scss";

export const Welcome: React.FC = () => {
  return (
    <section id="welcome-section" className="wrapping-container">
      <StaticCube base_size={250} />
      <div className="greeting-container">
        <h2 className="big-message" id="welcome-message">
          Welcome, <i>fellow developer</i>, to the home of the{" "}
          <strong>
            Alliance of Computer Science Students of the University of the
            Philippines Los Baños
          </strong>
          .
        </h2>
      </div>
    </section>
  );
};
