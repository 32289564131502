/**
 * Mission.tsx
 *
 * Component for the mission section of the website.
 *
 */

import React, { useRef } from "react";

import "../Homepage.scss";
import "./Mission.scss";

import { useIntersection } from "react-use";
import mission_img from "../../../assets/img/mission-cube.png";
import { useEffect } from "react";
import { useState } from "react";

export const Mission: React.FC = () => {
  const section_ref = useRef<HTMLElement>(null);
  // Use the Intersection Observer API to track the visibility of the text elements.
  const intersection = useIntersection(section_ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  const [headingClass, setHeadingClass] = useState("");
  const [paragraphClass, setParagraphClass] = useState("");
  let heading_has_appeared = false;

  useEffect(() => {
    if (intersection?.isIntersecting && !heading_has_appeared) {
      heading_has_appeared = true;
      setHeadingClass("heading-appear");
      setParagraphClass("paragraph-appear");
    }
  }, [intersection?.isIntersecting]);

  return (
    <section className="text-section" id="mission-section" ref={section_ref}>
      <figure className="cube-area">
        <img src={mission_img} className="section-img"></img>
      </figure>
      <main className="text-area">
        <h2 className={headingClass}>Mission</h2>
        <p className={paragraphClass}>
          We aim to: (1) develop students worthy of the title “Iskolar ng Bayan
          para sa Bayan”; (2) continuously expand the knowledge, wisdom, skills,
          and abilities of each member through the principles of the
          organization; (3) assist the university, more importantly its
          students, with all that the organization can offer; and (4) assist in
          building individuals who shall lead in solving different problems of
          the community using technology, especially computing sciences, as its
          tool.
        </p>
      </main>
      {/* Reserve an area for the cube. */}
    </section>
  );
};
