/**
 * Partners.tsx
 *
 * Component for the Partners section.
 *
 */

import React from "react";
import Img, { GatsbyImageFixedProps } from "gatsby-image";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";

import "./Partners.scss";

interface PartnersProps {
  visible?: boolean;
}

export const Partners: React.FC<PartnersProps> = ({ visible = true }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fixed: {
            originalName: {
              in: ["amihan.png", "freelancer.png", "opentext.png"]
            }
          }
        }
        sort: { fields: fixed___originalName }
      ) {
        nodes {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  `);

  if (visible) {
    return (
      <section id="partners-section">
        <div className="partners-text-container">
          <h1 id="partners-message">We have worked with...</h1>
          <div className="partner-images">
            {data.images.nodes.map(
              (image: GatsbyImageFixedProps, i: number) => (
                <Img key={i} fixed={image.fixed} className="partner-image" />
              )
            )}
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};
