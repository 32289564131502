import React from "react";
import { Homepage } from "../components/Homepage/Homepage";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

const HomePage: React.FC = () => {
  return (
    <Layout>
      <SEO title="ACSS UPLB - Alliance of Computer Science Students UPLB" />
      <Homepage />
    </Layout>
  );
};

export default HomePage;
