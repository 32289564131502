/**
 * Intro.tsx
 *
 * Component for the intro section of the home page.
 *
 */

import React, { useEffect, useRef } from "react";
import { useIntersection } from "react-use";

import "../Homepage.scss";
import "./Intro.scss";
import "../MissionAndVision.scss";
import intro_video from "../../../assets/video/intro_splash-720w.mp4";

interface IntroProps {
  homepage_container_ref: React.RefObject<HTMLElement>;
}

interface useSplashVideoReferences {
  video_ref: React.RefObject<HTMLVideoElement>;
  container_ref: React.RefObject<HTMLDivElement>;
}

/**
 * useSplashVideo
 * Custom Hook for managing the the splash screen sequence.
 * @param homepage_container_ref the ref of the home page container, which is used to fade in/out the black background.
 */
const useSplashVideo = (
  homepage_container_ref: React.RefObject<HTMLElement>
): useSplashVideoReferences => {
  const video_ref = useRef<HTMLVideoElement>(null);
  const container_ref = useRef<HTMLDivElement>(null);

  // Use the Intersection Observer API through the useIntersection Hook
  const intersection = useIntersection(video_ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.01,
  });

  // Change the background depending on whether the user has scrolled past the intro section by adding a class.
  useEffect(() => {
    if (intersection && !intersection.isIntersecting) {
      homepage_container_ref.current?.classList.add("home-page-undarken");
      video_ref.current?.classList.add("hidden");
    } else {
      homepage_container_ref.current?.classList.remove("home-page-undarken");
    }
  }, [intersection?.isIntersecting]);

  return {
    container_ref,
    video_ref,
  };
};

/**
 * Intro
 * Component proper.
 * @param homepage_container_ref the Ref object for the home page container.
 */
export const Intro: React.FC<IntroProps> = ({ homepage_container_ref }) => {
  const { container_ref, video_ref } = useSplashVideo(homepage_container_ref);
  const scroll_indicator_ref = useRef<HTMLDivElement>(null);

  return (
    <section id="intro-section" ref={container_ref}>
      <div className="video-container" ref={container_ref}>
        {/* For the video to play automatically on all browsers, we must mute and play the video inline. */}
        <video
          className="intro-video"
          src={intro_video}
          playsInline
          muted
          autoPlay
          ref={video_ref}
          onContextMenu={(e) => e.preventDefault()}
          onEnded={() => {
            video_ref.current?.classList.add("hidden");
            scroll_indicator_ref.current?.classList.remove("hidden");
          }}
          onSuspend={() => {
            // Hide video if the device is in low power mode.
            video_ref.current?.classList.add("hidden");
            scroll_indicator_ref.current?.classList.remove("hidden");
          }}
        ></video>
        <div className="scroll-indicator hidden" ref={scroll_indicator_ref}>
          <span className="mouse-icon">
            <span className="mouse-scroll-wheel"></span>
          </span>
          {/* Arrow Down Icon */}
          <svg
            className="arrow-down-icon"
            width="28"
            height="6"
            viewBox="0 0 28 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="-1"
              x2="13.5602"
              y2="-1"
              transform="matrix(0.961524 0.274721 -0.420009 0.90752 0 2)"
              stroke="#C9C9C9"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <line
              x1="1"
              y1="-1"
              x2="13.5602"
              y2="-1"
              transform="matrix(-0.961524 0.274721 0.420009 0.90752 28 2)"
              stroke="#C9C9C9"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};
