/**
 * Intermission1.tsx
 *
 * Component for the first Intermission section.
 *
 */

import React, { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";
import Img, { GatsbyImageFluidProps } from "gatsby-image";

import "./Intermission1.scss";
import "../Homepage.scss";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";

export const Intermission1: React.FC = () => {
  // Styling for the box shadow in the images.
  const image_boxShadow = {
    boxShadow:
      "0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
  };

  const intermission1_container_ref = useRef<HTMLDivElement>(null);
  const intermission1_intersection = useIntersection(
    intermission1_container_ref,
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.125,
    }
  );

  const data = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fluid: {
            originalName: {
              in: [
                "photo1.jpg"
                "photo2.jpg"
                "photo3.jpg"
                "photo4.jpg"
                "photo5.jpg"
                "photo6.jpg"
              ]
            }
          }
        }
        sort: { fields: fluid___originalName }
      ) {
        nodes {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  `);

  const topPhotos = data.images.nodes.slice(0, 3);
  const bottomPhotos = data.images.nodes.slice(3, 6);

  const [topClasses, setTopClasses] = useState(
    topPhotos.map((_: any, i: number) => {
      return "grid-photo image-" + "top" + "-" + (i + 1) + " image-initial";
    })
  );
  const [bottomClasses, setBottomClasses] = useState(
    bottomPhotos.map((_: any, i: number) => {
      return "grid-photo image-" + "bottom" + "-" + (i + 1) + " image-initial";
    })
  );
  let heading_has_appeared = false;

  useEffect(() => {
    if (intermission1_intersection?.isIntersecting && !heading_has_appeared) {
      heading_has_appeared = true;
      setTopClasses(
        topPhotos.map((_: any, i: number) => {
          return (
            "grid-photo image-" +
            "top" +
            "-" +
            (i + 1) +
            " image-visible-" +
            "top" +
            "-" +
            (i + 1)
          );
        })
      );
      setBottomClasses(
        bottomPhotos.map((_: any, i: number) => {
          return (
            "grid-photo image-" +
            "bottom" +
            "-" +
            (i + 1) +
            " image-visible-" +
            "bottom" +
            "-" +
            (3 - i)
          );
        })
      );
    }
  }, [intermission1_intersection?.isIntersecting]);

  return (
    <section id="intermission1-section">
      <div
        ref={intermission1_container_ref}
        className="intermission1-container"
      >
        <div className="image-grid image-grid-top">
          {topPhotos.map((image: GatsbyImageFluidProps, i: number) => (
            <div key={i} className={topClasses[i]}>
              <Img
                fluid={image.fluid}
                style={image_boxShadow}
                alt={`Intermission ${i}`}
              />
            </div>
          ))}
        </div>
        <div className="intermission1-text-container">
          <h2 className="big-message" id="intermission1-message">
            Who are we?
          </h2>
          <h3 className="big-message deemphasized" id="intermission1-body">
            Established in December 17, 2014, The Alliance of Computer Science
            Students - UPLB is an officially-recognized service-oriented
            academic organization at the University of the Philippines Los
            Baños.
          </h3>
        </div>
        <div className="image-grid image-grid-bottom">
          {bottomPhotos.map((image: GatsbyImageFluidProps, i: number) => (
            <div key={i} className={bottomClasses[i]}>
              <Img
                fluid={image.fluid}
                style={image_boxShadow}
                alt={`Intermission ${i}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
