/**
 * Homepage.tsx
 *
 * Component for the home page of the website.
 *
 */

import React, { useRef } from "react";

import { Intro } from "./Intro/Intro";
import { Welcome } from "./Welcome/Welcome";
import { Mission } from "./Mission/Mission";
import { Vision } from "./Vision/Vision";
import { Intermission1 } from "./Intermission1/Intermission1";
import { Partners } from "./Partners/Partners";

import "./Homepage.scss";
import "../../styles/layout.scss";

export const Homepage: React.FC = () => {
  const container_ref = useRef<HTMLElement>(null); // We get the ref of the container so that we can modify the background of the container.

  return (
    <main className="page-layout home-page" ref={container_ref}>
      <Intro homepage_container_ref={container_ref} />
      <Welcome />
      <Intermission1 />
      <section className="wrapping-container" id="mission-and-vision">
        <Mission />
        <Vision />
      </section>
      <Partners />
    </main>
  );
};
